import { addDataToMap } from 'kepler.gl/actions'
import { processRowObject } from 'kepler.gl/processors'
import { fetchCubeData } from '../services/cubeQuery'
import config from './config.json'

export const fetchHarrisCounty =
  ({ variable }) =>
  async (dispatch) => {
    try {
      const commonFilters = [
        {
          dimension: 'IncidentDamageAssessment.incidentId',
          operator: 'equals',
          values: [variable],
        },
      ]
      const [countyData, destroyed, major, minor, moderate] = await Promise.all(
        [
          fetchCubeData('HARRIS_COUNTY'),
          fetchCubeData('DESTROYED_DATA', commonFilters),
          fetchCubeData('MAJOR_DATA', commonFilters),
          fetchCubeData('MINOR_DATA', commonFilters),
          fetchCubeData('MODERATE_DATA', commonFilters),
        ]
      )

      const processedCountyData = processRowObject(countyData.rawData())
      const processedDestroyedData = processRowObject(destroyed.rawData())
      const processedMajorData = processRowObject(major.rawData())
      const processedMinorData = processRowObject(minor.rawData())
      const processedModerateData = processRowObject(moderate.rawData())

      dispatch(
        addDataToMap({
          datasets: [
            {
              info: {
                id: 'county_data',
                label: 'HARRIS_COUNTY',
              },
              data: processedCountyData,
            },
            {
              info: {
                id: 'destroyed_data',
                label: 'DESTROYED_DATA',
              },
              data: processedDestroyedData,
            },
            {
              info: {
                id: 'major_data',
                label: 'MAJOR_DATA',
              },
              data: processedMajorData,
            },
            {
              info: {
                id: 'minor_data',
                label: 'MINOR_DATA',
              },
              data: processedMinorData,
            },
            {
              info: {
                id: 'moderate_data',
                label: 'MODERATE_DATA',
              },
              data: processedModerateData,
            },
          ],
          options: {
            centerMap: true,
            readOnly: false,
          },
          config: config,
        })
      )
    } catch (error) {
      console.error('Error fetching data from Cube.js:', error)
    }
  }
