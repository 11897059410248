const tryRequire = (path) => {
  try {
    const image = require(`${path}`)
    return image
  } catch (err) {
    return false
  }
}

const images = {
  TAMUlogoWhite:
    tryRequire('./TAMUlogoWhite.png') || require('./questionMark.png'),
  TDISlogoWhite:
    tryRequire('./TDISlogoWhite.png') || require('./questionMark.png'),
  gloLogo: tryRequire('./glo.png') || require('./questionMark.png'),
}

export default images
