import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from 'react-router-dom'
import Map from './components/Map'
import ErrorBoundary from './ErrorBoundary'
import './App.css'
import ImgAsset from './public'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Divider,
} from '@mui/material'
function AppBarComponent({ subtitle = '' }) {
  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: 'rgba(50, 71, 85, 1)',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        padding: '0 20px',
      }}
    >
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Left Logo */}
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="TDIS Logo"
            style={{
              marginRight: '10px',
              transform: 'scale(1)',
              transition: 'transform 0.3s',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = 'scale(1.1)')
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            <img
              src={ImgAsset.TDISlogoWhite}
              alt="TDIS Logo"
              style={{ width: '180px', height: '60px' }}
            />
          </IconButton>
        </Box>

        {/* Title */}
        <Typography
          variant="h5"
          style={{
            fontWeight: 700,
            color: 'white',
            letterSpacing: '2px',
            textTransform: 'uppercase',
            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
          }}
        >
          <span style={{ color: '#f0c14b', fontSize: '1.5em' }}>D</span>
          amage <span style={{ color: '#f0c14b', fontSize: '1.5em' }}>A</span>
          ssessment {subtitle && `- ${subtitle}`}
        </Typography>

        {/* Right Logo */}
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="TAMU Logo"
            style={{
              transform: 'scale(1)',
              transition: 'transform 0.3s',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = 'scale(1.1)')
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            <img
              src={ImgAsset.TAMUlogoWhite}
              alt="TAMU Logo"
              style={{ width: '190px', height: '65px' }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

function MapWithVariable() {
  const { variable } = useParams()

  // Use variable or default to 2
  const resolvedVariable = variable || '2'

  // Map variables to their corresponding subtitles
  const subtitleMap = {
    2: 'Hurricane Harvey 2017',
    3: 'Houston Derecho 2024',
    harvey: 'Hurricane Harvey 2017',
    derecho: 'Houston Derecho 2024',
  }
  const subtitle = subtitleMap[resolvedVariable] || ''

  return (
    <>
      <AppBarComponent subtitle={subtitle} />
      <Map variable={resolvedVariable} />
    </>
  )
}

function FooterComponent() {
  return (
    <footer
      style={{
        backgroundColor: 'rgba(50, 71, 85, 1)',
        padding: '20px 40px',
        color: 'white',
      }}
    >
      <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: '10px',
        }}
      >
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          TDIS © 2024. All Rights Reserved.
        </Typography>

        <Box
          style={{
            display: 'flex',
            gap: '20px',
          }}
        >
          <Typography
            variant="body2"
            component="a"
            href="#"
            style={{
              textDecoration: 'none',
              color: 'white',
              transition: 'color 0.3s',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = '#f0c14b')}
            onMouseLeave={(e) => (e.currentTarget.style.color = 'white')}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="#"
            style={{
              textDecoration: 'none',
              color: 'white',
              transition: 'color 0.3s',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = '#f0c14b')}
            onMouseLeave={(e) => (e.currentTarget.style.color = 'white')}
          >
            Terms of Use
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="#"
            style={{
              textDecoration: 'none',
              color: 'white',
              transition: 'color 0.3s',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = '#f0c14b')}
            onMouseLeave={(e) => (e.currentTarget.style.color = 'white')}
          >
            Contact Us
          </Typography>
        </Box>

        <Typography variant="body2" style={{ fontStyle: 'italic' }}>
          Empowering Data Intelligence
        </Typography>
      </Box>
    </footer>
  )
}

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <main className="content">
          <Routes>
            <Route path="/:variable" element={<MapWithVariable />} />

            <Route path="/" element={<MapWithVariable />} />
          </Routes>
        </main>
        <FooterComponent />
      </ErrorBoundary>
    </Router>
  )
}

export default App
