import { handleActions } from 'redux-actions'
import { ActionTypes, INIT } from 'kepler.gl/actions'

const initialState = {
  loaded: false,
  selectedFeature: null,
}

const appReducer = handleActions(
  {
    [INIT]: (state, action) => ({
      ...state,
      loaded: true,
    }),

    [ActionTypes.LAYER_CLICK]: (state, { payload }) => {
      console.log('Layer click info:', payload) // Log click info (feature data)

      // Extract the clicked feature from the payload
      const clickedFeature = payload?.info
      if (clickedFeature) {
        return {
          ...state,
          selectedFeature: clickedFeature, // Store selected point data
        }
      }
      return state
    },

    [ActionTypes.SET_FILTER]: (state, { payload }) => {
      console.log('filter payload....', payload)
      if (payload && payload?.prop == 'value' && payload?.value) {
        return {
          ...state,
          selectedCounty: payload?.value,
        }
      }
      return state
    },
  },
  initialState
)

export default appReducer
