import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import KeplerGl from 'kepler.gl'
import { fetchHarrisCounty } from '../actions/fetchActions'
import { AutoSizer } from 'react-virtualized'
import { resetMapConfig } from 'kepler.gl/actions'

const Map = ({ variable }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Map "harvey" to 4 and "derecho" to 5, default to 2 if not provided
    let resolvedVariable
    if (variable === 'harvey') {
      resolvedVariable = '4'
    } else if (variable === 'derecho') {
      resolvedVariable = '5'
    } else {
      resolvedVariable = variable || '4'
    }

    // Reset map and fetch data
    dispatch(resetMapConfig())
    dispatch(fetchHarrisCounty({ variable: resolvedVariable }))
  }, [dispatch, variable])

  return (
    <AutoSizer>
      {({ height, width }) => (
        <KeplerGl
          id="flood_zones"
          width={width}
          height={height}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          theme="base"
        />
      )}
    </AutoSizer>
  )
}

export default Map
